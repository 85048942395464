<template>
  <div class="footer-end">
    <MNewsletterSection class="mobile-only" />
    <div class="footer-bottom">
      <div class="links-section">
        <div v-for="(group, idx) in linkGroups" :key="idx" class="group">
          <p class="title-1 title">
            {{ group.title }}
          </p>
          <ul class="group-links">
            <li class="content" v-for="item in group.links" :key="item._uid">
              <template v-if="item.component === FooterItemType.Link">
                <a
                  v-if="item.openNewTab"
                  class="paragraph-2 link"
                  :href="item.href"
                  target="_blank"
                >
                  {{ item.title }}
                </a>
                <router-link v-else class="paragraph-2 link" :to="item.href">
                  {{ item.title }}
                </router-link>
              </template>
              <template v-else>
                <p class="title-1 title" v-if="item.isTitle">
                  {{ item.title }}
                </p>
                <p class="paragraph-2" v-else>
                  {{ item.title }}
                </p>
              </template>
            </li>
          </ul>
        </div>
      </div>
      <div class="links-section-mobile">
        <MLinksAccordian
          v-for="(group, idx) in linkGroups"
          :key="idx"
          :link-group="group"
          :is-open="activeGroup === group.id"
          @open="openGroup"
        />
      </div>
      <div class="country-section">
        <MNewsletterSection class="desktop-only" />
        <MPayments />
        <MCountrySelection :mode="'mobile'" :prompt-location-change="isMobileDevice || isTabletDevice" />
      </div>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import isOnPage from 'common/mixins/isOnPage';
import windowSize from 'common/mixins/windowSize';
import { mapGetters } from 'vuex';
import CmsRender from 'src/modules/storyblok/mixins/CmsRender';
import { FooterItemType } from '../store/types';

export default {
  name: 'FooterLinks',
  components: {
    MLinksAccordian: () => import('common/components/molecules/footer/m-links-accordian'),
    MCountrySelection: () => import('common/components/molecules/m-country-selection'),
    MNewsletterSection: () => import('common/components/molecules/footer/m-newsletter-section.vue'),
    MPayments: () => import('common/components/molecules/footer/m-payments.vue')
  },
  mixins: [isOnPage, CmsRender, windowSize],
  data () {
    return {
      activeGroup: '',
      blockKey: 'blocks/footer'
    };
  },
  computed: {
    ...mapGetters({
      stories: 'storyblok/getStories'
    }),
    linkGroups () {
      const storeCode = currentStoreView().storeCode;
      return this.stories?.[`${storeCode}-footer`]?.story || [];
    },
    FooterItemType () {
      return FooterItemType;
    }
  },
  methods: {
    openGroup (id) {
      this.activeGroup = this.activeGroup === id ? '' : id;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.footer-end {
  padding: 48px 80px;
  border-bottom: 1px solid var(--neutral-100);
  @include for-mobile {
    padding: 40px 20px;
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-4xl);

  @include for-mobile {
    flex-direction: column;
  }

  .links-section-mobile {
    display: none;
    flex-direction: column;

    @media (max-width: $tablet-max) {
      display: flex;
    }
  }

  .links-section {
    display: flex;
    gap: var(--spacing-4xl);
    justify-content: space-between;

    @media (max-width: $tablet-max) {
      display: none;
    }

    .group {
      .title {
        margin-bottom: 20px;
      }
      .content {
        margin-top: var(--spacing-lg);
      }

      .group-links {
        display: flex;
        flex-direction: column;
      }
      .link {
        color: var(--neutral-700);
      }
    }
  }

  .country-section {
    max-width: 100%;
    @media  screen and (min-width: $desktop-min) and (max-width: $desktop-l-min) {
      max-width: 300px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    ::v-deep.shipping-info {
      img.country-image {
        margin: 0;
        vertical-align: middle;
      }
      margin-top: var(--spacing-xl);
    }
  }
}
</style>
